import React from "react"
import { Flex, SimpleGrid } from "@chakra-ui/core"
import { navigate } from "gatsby"
import { FluidObject } from "gatsby-image"

import MaxWidthGrid from "../components/Layout/MaxWidthGrid"
import Heading from "../components/typography/Heading"
import Body from "../components/typography/Body"
import CenteredImage from "../components/CenteredImage"
import SEO from "../components/boilerplate/seo"
import { AccessoryCollectionQuery } from "../../graphql-types"
import { bp } from "../utils/MediaQueries"
import { placeholderImage } from "../utils"
export interface AccessoryCollectionProps {
  data: AccessoryCollectionQuery
  pageContext: {
    pageTitle: string
    accessorySlugs: Array<string>
  }
  location: Location
}

const AccessoryCollection: React.FC<AccessoryCollectionProps> = ({
  data,
  pageContext,
  location,
}) => {
  return (
    <>
      <SEO
        title={pageContext.pageTitle}
        description="Are you looking for awesome and affordable beach cruiser bike accessories to customize out your ride? If so, you’ve come to the right place. Sixthreezero offers an assortment of cool accessories to customize all of our bicycles for sale."
        location={location}
      />
      <MaxWidthGrid pt={["4rem", null, null, null, "10rem"]}>
        <Flex
          gridColumn={["1 / 3", null, null, null, "2 / 15"]}
          pb={["2rem", null, null, null, "6.25rem"]}
          justifyContent={["center", null, null, null, "flex-start"]}
        >
          <Heading size="5" fontWeight="bold" color="night">
            {pageContext.pageTitle}
          </Heading>
        </Flex>
        <SimpleGrid
          gridColumn={bp("1 / 3", "2 / 14")}
          columns={bp(1, 3)}
          spacingX="4.5rem"
          spacingY="3rem"
        >
          {data.collections.edges.map((val, i) => (
            <Flex
              key={`accessory-category-${val.node.internalTitle}`}
              paddingX={[null, null, null, null, "0.25rem"]}
              flexDir="column"
              justifyContent="center"
              onClick={() => navigate(`/accessories/${val.node.internalTitle}`)}
              cursor="pointer"
            >
              <CenteredImage
                fluid={
                  (val.node.productListImage?.fluid ??
                    placeholderImage) as FluidObject
                }
                w="100%"
                h="20.5rem"
                py="2rem"
              />
              <Body fontWeight="bold" color="night" textAlign="center">
                {val.node.title}
              </Body>
            </Flex>
          ))}
        </SimpleGrid>
      </MaxWidthGrid>
    </>
  )
}

export default AccessoryCollection
