import { graphql } from "gatsby"

import AccessoryCollection from "../views/AccessoryCollection"

export const query = graphql`
  query AccessoryCollection($accessorySlugs: [String]!) {
    collections: allContentfulAccessoryCollectionType(
      filter: { internalTitle: { in: $accessorySlugs }, hidden: { ne: true } }
    ) {
      edges {
        node {
          title
          internalTitle
          productListImage {
            fluid(maxWidth: 1000, quality: 85) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
        }
      }
    }
  }
`

export default AccessoryCollection
